<template>
    <div>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">

            <el-form-item label="配置日期：">
                <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    align="right" size="small">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="操作系统：">
                <el-select v-model="dataForm.platformName" clearable placeholder="请选择" size="small">
                    <el-option v-for="item in osOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="用户语种：">
                <el-select v-model="dataForm.lang" clearable placeholder="请选择" size="small">
                    <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="是否强更新：">
                <el-select v-model="dataForm.remindUpgrade" clearable placeholder="请选择" size="small">
                    <el-option v-for="item in remindUpgradeOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- 
            <el-form-item label="任务状态：">
                <el-select v-model="dataForm.status" clearable placeholder="请选择" size="small" >
                    <el-option v-for="item in statusOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item> -->

            <el-form-item label="客户端版本：">
                <el-input v-model="dataForm.appVersion" placeholder="请输入版本号" class="input-width"></el-input>
            </el-form-item>

            <el-form-item label="配置人">
                <el-select v-model="dataForm.updatedBy" clearable placeholder="配置人" size="small">
                    <el-option v-for="item in sysUser" :key="item.userId" :label="item.realName" :value="item.userId">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button @click="getDataList()" type="primary" size="small">搜索</el-button>
                <el-button @click="refresh()" size="small">重置</el-button>
                <el-button @click="addOrUpdateHandle()" size="small"
                    style="background-color: #009DD9; color: #fff;">+新增配置</el-button>
                <!-- <el-button @click="exportList()" size="small" style="background-color: #00BFBF; color: #fff;">导出</el-button> -->
            </el-form-item>

        </el-form>

        <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%;">
            <el-table-column prop="id" header-align="center" align="center" width="80" label="任务ID">
            </el-table-column>
            <el-table-column prop="platformName" header-align="center" align="center" label="操作系统">
            </el-table-column>
            <el-table-column prop="appVersion" header-align="center" align="center" label="客户端版本">
            </el-table-column>
            <el-table-column prop="lang" header-align="center" align="center" label="用户语言">
            </el-table-column>
            <el-table-column prop="versionDescription" header-align="center" align="center" label="版本文案">
            </el-table-column>
            <el-table-column prop="appStoreUrl" header-align="center" align="center" label="应用商店链接">
            </el-table-column>
            <el-table-column prop="remindUpgrade" header-align="center" align="center" label="是否强更">
                <template slot-scope="scope">
                    <div>
                        <el-tag v-if="scope.row.remindUpgrade == true" type="success">是</el-tag>
                        <el-tag v-else-if="scope.row.remindUpgrade == false" type="danger">否</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" header-align="center" align="center" label="配置时间">
            </el-table-column>
            <el-table-column prop="updatedBy" header-align="center" align="center" label="配置人">
            </el-table-column>
            <!-- <el-table-column prop="status" header-align="center" align="center" label="任务状态">
                <template slot-scope="scope">
                    <div>
                        <el-tag v-if="scope.row.status === 1" type="success">进行中</el-tag>
                        <el-tag v-else-if="scope.row.status === 2" type="danger">手动终止</el-tag>
                        <el-tag v-else-if="scope.row.status === 3" type="warning">自动终止</el-tag>

                    </div>
                </template>
            </el-table-column> -->
            <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">配置编辑
                    </el-button>
                    <!-- <el-button v-if="scope.row.status !== 1" type="text" size="small" style="color: rgba(0, 191, 191, 1)"
                        @click="changeStatus(scope.row.id)">终止任务
                    </el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
import AddOrUpdate from "./client-add-or-update";
import { getCreatedByName } from "@/utils/sysUser";

const sourceDataForm = {
    id: '',
    platformName: '',
    lang: '',
    remindUpgrade: '',
    // status: '',
    appVersion: '',
    updatedBy: '',
    startTime: '',
    endTime: '',
    createTimeArr: [],
}

export default {
    data() {
        return {
            dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
            sysUser: [],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }],
            },
            osOpt: [
                {
                    value: ' ',
                    label: 'ALL'
                },
                {
                    value: 'ios',
                    label: 'IOS客户端版本'
                },
                {
                    value: 'android',
                    label: 'Android客户端版本'
                },
            ],
            remindUpgradeOpt: [
                {
                    value: 1,
                    label: '是'
                },
                {
                    value: 0,
                    label: '否'
                }
            ],
            // statusOpt: [
            //     {
            //         value: 0,
            //         label: 'all'
            //     },
            //     {
            //         value: 1,
            //         label: '进行中'
            //     },
            //     {
            //         value: 2,
            //         label: '手动终止'
            //     },
            //     {
            //         value: 3,
            //         label: '自动终止'
            //     }
            // ],
            langOpt: [
                {
                    value: 'ar',
                    label: '阿拉伯语'
                },
                {
                    value: 'en',
                    label: '英语'
                },
                {
                    value: ' ',
                    label: 'ALL'
                }
            ],
            url: '',
            addOrUpdateVisible: true
        }
    },
    components: {
        AddOrUpdate
    },
    async created() {
        this.sysUser = await this.$store.dispatch("GetSysUser", {});
    },
    activated() {
        this.getDataList()
    },
    methods: {
        refresh() {
            this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
            this.pageIndex = 1
            this.pageSize = 10
            this.getDataList()
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true

            this.$http({
                url: this.$http.adornUrl('/sys/config/queryHistoryVersion'),
                method: 'post',
                data: this.$http.adornData({
                    ...this.dataForm,
                    startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
                    endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
                    pageNum: this.pageIndex,
                    pageSize: this.pageSize,
                })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataList = data.page.records
                    this.totalPage = data.page.total
                } else {
                    this.$message.error(data.msg)
                }
                this.dataListLoading = false
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },

        // 新增 / 修改
        addOrUpdateHandle(row) {
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(row)
            })
        },

        // exportList() {
        //     const exportData = {
        //         ...this.dataForm,
        //         updateTimeStart: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        //         updateTimeEnd: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
        //     }
        //     const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
        //     window.open(
        //         this.$http.adornUrl('/admin/gift/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
        //         "_blank"
        //     );
        // }
    },
}
</script>
<style lang="scss" scoped>
// ::v-deep .el-dialog__header {
//   padding: 3vh 2vw 0 2vw;
// }

::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
